<template>
  <div class="column">
    <div class="text-red text-subtitle1" v-if="error">{{template_text.SELECTONE}}</div>

    <div class="row q-gutter-xs">
      <q-option-group
          :options="btn_opt"
          type="checkbox"
          v-model="form"
      />

    </div>
    <div class="col self-end q-pa-md" v-if="dnext||forcenext">
      <q-btn color="secondary"  push icon-right="navigate_next" :label="template_text.NEXT" @click="onNext"/>
    </div>
  </div>
</template>

<script>
export default {
  name: "checkboxes_input",
  data(){
    return {
      form:this.value||[],
      error:false,

    }

  },
  watch: {
    form: {
      handler: function(newValue) {
        this.$emit('input', newValue);
      },
      deep: true,
    }
  },
  computed:{
    labels:function(){return this.attribut.label.find(o => o.language === this.language)},
    btn_opt:function() {
      let data = []
      for (let i in this.attribut.values){
        data.push({value:this.attribut.values[i],label:this.labels[i]})
      }
      return data
    }
  },
  props:{
    value:Array,
    attribut:Object,
    forcenext:Boolean,
    dnext:Boolean,
    template_text:Object,
    mandatory:Boolean,
    language:String,
  },
  methods:{
    validate(){
      if(this.form.length>0){
        return true
      }
      this.error=true;
      return false
    },
    onBtn(value){
      this.form=value;
      this.$emit('input', value);
      if(!this.forcenext) this.onNext()
    },
    async onNext(){
      if(await this.validate()) this.$emit('next');

    }
  },
}
</script>

<style scoped>

</style>
